<template>
  <div class="market-configuration-risk-configuration-tab">
    <!-- Risk (Limits) -->
    <div
      class="risk-configuration-section limit"
    >
      <div
        class="risk-configuration-group limit"
        v-if="limitConfigurationType === 'SIMPLE_TIERED'"
      >
        <div class="label">
          MARKET TIER
        </div>
        <div class="risk-dropdown">
          <Dropdown :label="mainTier?.label || 'Please select'">
            <DropdownItem
              v-for="tier in limitTiers"
              :key="tier.id"
              clickable
              placeholder
              @click="applyLimitTier(tier.id)"
            >
              {{ tier.label }}
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
      <div
        class="risk-configuration-group limit"
        v-if="limitConfigurationType === 'MAIN_ALT_TIERED'"
      >
        <div class="label">
          MARKET TIER - MAIN LINE
        </div>
        <div class="risk-dropdown">
          <Dropdown :label="mainTier?.label || 'Please select'">
            <DropdownItem
              v-for="tier in limitTiers"
              :key="tier.id"
              clickable
              @click="applyLimitTier(tier.id, 'main')"
            >
              {{ tier.label }}
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
      <div
        class="risk-configuration-group limit"
        v-if="limitConfigurationType === 'MAIN_ALT_TIERED'"
      >
        <div class="label">
          MARKET TIER - ALTERNATIVE
        </div>
        <div class="risk-dropdown">
          <Dropdown :label="altTier?.label || 'Please select'">
            <DropdownItem
              v-for="tier in limitTiers"
              :key="tier.id"
              clickable
              placeholder
              @click="applyLimitTier(tier.id, 'alt')"
            >
              {{ tier.label }}
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { find } from 'lodash';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import { createTierColumns } from '@/services/helpers/market-tiers';

export default {
  components: {
    Dropdown,
    DropdownItem,
  },
  props: {
    limitConfigurationType: {
      type: String,
      default: 'SIMPLE_TIERED',
    },
  },
  emits: {
    'update:riskConfiguration': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const limitTiers = createTierColumns();
    const mainTier = ref(null);
    const altTier = ref(null);

    const applyLimitTier = (tier, type) => {
      if (props.limitConfigurationType === 'SIMPLE_TIERED') {
        mainTier.value = find(limitTiers, (t) => t.id === tier);
        const updatedRiskConfiguration = {
          type: props.limitConfigurationType,
          tier: mainTier.value.id,
        };
        emit('update:riskConfiguration', updatedRiskConfiguration);
        return;
      }
      if (props.limitConfigurationType === 'MAIN_ALT_TIERED') {
        if (type === 'main') {
          mainTier.value = find(limitTiers, (t) => t.id === tier);
        } else {
          altTier.value = find(limitTiers, (t) => t.id === tier);
        }
        if (mainTier.value && altTier.value) {
          const updatedRiskConfiguration = {
            type: props.limitConfigurationType,
            mainTier: mainTier.value.id,
            altTier: altTier.value.id,
          };
          emit('update:riskConfiguration', updatedRiskConfiguration);
        }
      }
    };

    return {
      applyLimitTier,
      limitTiers,
      mainTier,
      altTier,
    };
  },
};
</script>

<style lang="scss">
.market-configuration-risk-configuration-tab {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 0 0;
  box-sizing: border-box;
  width: 100%;
  min-height: 55px;

  .select {
    min-width: 110px;

    .select-dropdown {
      min-width: 100%;
      width: auto;
    }
  }

  &__after-inputs {
    display: flex;
    align-items: center;
    gap: 8px;

    .table-number-input {
      min-width: 44px;
      max-width: 44px;
      width: 44px;
    }

    & > span {
      color: #6D6D6D;
      font-weight: 400;
    }
  }

  .risk-configuration-section {
    width: 100%;
    margin-bottom: 16px;
    display: flex;

    .risk-configuration-group {
      margin-right: 16px;
      display: flex;
      flex-direction: column;

      .label {
        color: #A9A9A9;
        font-size: 10px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .risk-dropdown {
        width: 220px;

        .dropdown {
          z-index: unset;

          .dropdown-button {
            border-color: #DDDDDD;
          }
        }

        .dropdown-button {
          width: 100%;
          justify-content: space-between;
        }
      }

      .risk-input {
        display: flex;

        .increase-decrease-button {
          height: 33px;
          width: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #DDDDDD;
          cursor: pointer;

          &.left {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &.right {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          svg {
            path {
              stroke: #191414;
            }
          }
        }

        .table-number-input {
          width: 94px;

          input {
            text-align: center;
            border-color: #DDDDDD;
            height: 33px;
            border-right: 0;
            border-left: 0;
            border-radius: 0;
          }
        }
        .us-cent-dd {
          width: 120px;
        }
      }
    }
  }
}
</style>
